<template>
  <v-footer
    id="dashboard-core-footer"
    elevation="12"
    color="white"
  >
    <v-container class="text-center">
      <a
        href="http://lactalis.com.br/pt/"
        target="_blank"
      >
        <span style="color: #005dbc">
          &copy;{{ new Date().getFullYear() }} — <strong style="font-family: Poppins, sans-serif; font-size: 1rem">Rede Minipreço</strong> —
        </span>
        <span
          style="color: #005dbc; font-family: Poppins, sans-serif; font-size: 0.8rem"
        >
          &nbsp;{{ version }}
        </span>
      </a>
    </v-container>
  </v-footer>
</template>

<script>
  import packageJson from '../../../package.json'
  export default {
    name: 'DashboardCoreFooter',

    data () {
      return {
        version: this.getVersion(),
      }
    },
    methods: {
      getVersion () {
        const versionApi = localStorage.getItem('version_api')
        return 'versão: ' + (
          versionApi
            ? (versionApi + (process.env.NODE_ENV !== 'production' ? '(dev)' : '') + (packageJson.version !== versionApi ? '*' : ''))
            : packageJson.version + (process.env.NODE_ENV !== 'production' ? '(dev)' : '') + '*'
        )
      },
    },
  }
</script>
